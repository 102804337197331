import React from 'react'
import {Link} from 'gatsby'
import styles from './ProjectPreview.module.scss'
import PropTypes from 'prop-types'
import IsInView from '../IsInView'
import FitPic from '../fitPic/FitPic'
import {AppConsumer} from '../../AppProvider'
import {CURSORS} from '../../Config'

const ProjectPreview = ({slug, title, src, dimensions, altText, locale}) => {

    return (
        <AppConsumer>
            {({showCursor, hideCursor}) => (
                <Link to={`/${locale}/projects/${slug}`}
                      className={styles.Main}
                      onMouseEnter={() => showCursor(CURSORS.PLUS)}
                      onMouseLeave={() => hideCursor()}
                >
                    {src &&
                    <IsInView>
                        <FitPic
                            src={src}
                            width={dimensions.width}
                            height={dimensions.height}
                            altText={altText}
                            ratio="16:10"
                        />
                    </IsInView>
                    }
                    <h4 className={styles.Title}>
                        {title}
                    </h4>
                </Link>
            )}
        </AppConsumer>
    )
}

export default ProjectPreview

ProjectPreview.propTypes = {
    slug: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    locale: PropTypes.string.isRequired,
    src: PropTypes.string,
    dimensions: PropTypes.object,
    altText: PropTypes.string,
}