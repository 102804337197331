import React from 'react'
import styles from './ProjectGrid.module.scss'
import PropTypes from 'prop-types'
import ProjectPreview from './ProjectPreview'

const ProjectGrid = ({items, locale}) => (
    <div className={styles.Main}>
        {items.map(({node}, index) => {
            const {slugs, data} = node
            const {picture} = data.pictures[0]

            return (
                <ProjectPreview
                    key={index}
                    slug={slugs[0]}
                    title={data.title.text}
                    src={picture.url}
                    dimensions={picture.dimensions}
                    altText={picture.alt}
                    locale={locale}
                />
            )
        })}
    </div>
)

export default ProjectGrid

ProjectGrid.propTypes = {
    items: PropTypes.array.isRequired,
    locale: PropTypes.string.isRequired,
}