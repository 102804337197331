import React from 'react'
import {graphql} from 'gatsby'
import {Redirect} from '@reach/router'
import Layout from '../components/layout/Layout'
import Headline from '../components/headline/Headline'
import Container from '../components/container/Container'
import Text from '../components/text/Text'
import ProjectGrid from '../components/projects/ProjectGrid'
import RandomImages from '../components/randomImages/RandomImages'
import {sortProject} from '../misc/sortProject'
import Helmet from 'react-helmet'


const Page = ({data: {projects, allProject}, pageContext}) => {

    if (!projects) return <Redirect to={`/${pageContext.locale}/404`} noThrow/>

    const filteredProjects = allProject ? allProject.edges.filter(project => project.node.data.create_project_page === 'Yes') : []
    filteredProjects.sort(sortProject)

    return (
        <Layout
            locale={pageContext.locale}
            lang={pageContext.lang}
            alternatePath={pageContext.alternatePath}
            alternateLocale={pageContext.alternateLocale}
        >
            <Helmet>
                <title>{`Aquilas — ${projects.data.headline.text}`}</title>
            </Helmet>

            <section>
                <Headline headline={projects.data.headline}/>
                <Container>
                    <Text text={projects.data.text}/>
                </Container>

                <ProjectGrid locale={pageContext.locale} items={filteredProjects}/>
            </section>

            <RandomImages images={projects.data.random_images}/>
        </Layout>
    )

}

export const query = graphql`
query projectsQuery($lang: String!) {
  projects: prismicProjects(lang: {eq: $lang}) {
    data {
      headline {
        text
      }
      text {
        html
      }
      random_images {
        image {
          url
          dimensions {
            width
            height
          }
        }
      }
    }
  }
  allProject: allPrismicProject(filter: {lang: {eq: $lang}}) {
    edges {
      node {
        slugs
        data {
          order
          create_project_page
          title {
            text
          }
          pictures {
            picture {
              url
              dimensions {
                width
                height
              }
              alt
            }
          }
        }
      }
    }
  }
}
`

export default Page